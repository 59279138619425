import React from 'react';
import styles from './header.module.scss';
import logoHorizontal from '../../../assets/logo-horizontal.svg';
import logo from '../../../assets/logo.png';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../enums/router-paths.enum';
import { useMediaQuery } from 'react-responsive';
import MobileSidebar from "../mobile-sidebar/mobile-sidebar";

const Header: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className={styles.header}>
      {
        isMobile &&
        <div className={styles.headermobile}>
          <div className={styles.logocontainer}>
            <Link to={RouterPaths.Home}><img src={logo} alt='Logo club de basket des avenière veyrins thuellins'></img></Link>
          </div>
          <p className={styles.headertitle}>AVT Basketball</p>
          <div className={styles.burgercontainer}>
            <MobileSidebar></MobileSidebar>
          </div>
        </div>
      }
      {
        !isMobile && 
        <div className={styles.headercontainer}>
          <div className={styles.firsthalf}>
            <div className={styles.logocontainer}>
              <Link to={RouterPaths.Home}><img src={logoHorizontal} alt='Logo club de basket des avenière veyrins thuellins'></img></Link>
            </div>
          </div>
          <div className={styles.secondhalf}>
            <div className={styles.titlecontainer}><Link to={RouterPaths.Homelong}>LE CLUB</Link></div>
            <div className={styles.titlecontainer}><Link to={RouterPaths.Matchs}>MATCHS</Link></div>
            <div className={styles.titlecontainer}><Link to={RouterPaths.Trainings}>ENTRAINEMENTS</Link></div>
          </div>
        </div>
      }

    </div> 
  )
}

export default Header;
function useState<T>(arg0: boolean): [any, any] {
  throw new Error('Function not implemented.');
}

