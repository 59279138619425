import React from 'react';
import CSS from 'csstype';
import styles from './mosaic.module.scss';
import { ImageAndDescription, MosaicProps } from '../interfaces/mosaic.interface';
import { CloudinaryComponent } from '../../shared/cloudinary/Cloudinary';

const Mosaic: React.FC<MosaicProps> = ((mosaicProps) => {
  const { images, imageHeight, imagePerRow } = mosaicProps;

  const gridTemplateColumns = () => {
    let length = '';
    for (let i = 0; i < imagePerRow; i++) length += '1fr ';
    return length;
  };

  const mainStyle: CSS.Properties = {
    gridTemplateColumns: gridTemplateColumns(),
  };

  const computeStyle = (i: ImageAndDescription):CSS.Properties => {
    if(i.description){
      return {
        maxHeight: imageHeight+'px',
        marginBottom: '30px'
      }
    }
    return { maxHeight: imageHeight+'px'}
  }

  return (
    <div className={styles.mosaic} style={mainStyle}>
      {
        images.map((imgAndDesc,i) => (
          <div 
            className={styles.imagecontainer} 
            style={computeStyle(imgAndDesc)} 
            key={'mosaic-' + mosaicProps.imageHeight + '-h-' + mosaicProps.imagePerRow + '-r-' + i} 
          >
            <CloudinaryComponent imgName={imgAndDesc.image}></CloudinaryComponent>
            {
              imgAndDesc.description && (
                <p className={styles.imagedescription}>{imgAndDesc.description}</p>
              )
            }
            {
              imgAndDesc.id && (
                <span className={styles.removebutton} onClick={() => mosaicProps.callback(imgAndDesc.id)}>X</span>
              )
            }
          </div>
        )
        )
      }
    </div>
  );
});

export default Mosaic;
