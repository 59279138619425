import React from 'react';
import {
  AdvancedImage,
  responsive,
  placeholder,
} from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

 
export const CloudinaryComponent: React.FC<{imgName:string}> = (data) => {
  const cld = new Cloudinary({cloud: {cloudName: process.env.REACT_APP_CLOUDINARY_ID}});
  const img = cld.image(data.imgName);
  return (
    <div>
      <AdvancedImage
        cldImg={img}
        plugins={[ responsive({steps: 100}), placeholder()]
        }
        alt= 'Image du site AVTBasketball'
      />
    </div>
  )
};
