export enum RouterPaths {
  Home = "/",
  Homelong = "/accueil",
  Licenses = "/licences",
  Trainings = "/entrainements",
  Matchs = "/matchs",
  Gallery = "/galerie",
  LegalMentions = "/mentions-legales",
  Authentication = "/administration",
  LastArrivals = "/dernières-arrivées"
}