/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef} from 'react';
import { CloudinaryUploadProps } from '../interfaces/cloudinary.interface';

export const CloudinaryUpload: React.FC<CloudinaryUploadProps> = (props) => {
  const cloudinaryWidget: any = useRef();
  const untypedWindow = window as any;

  useEffect(() => {
    cloudinaryWidget.current = untypedWindow.cloudinary;
  });

  const upload = () => cloudinaryWidget.current.openUploadWidget({
    cloudName: process.env.REACT_APP_CLOUDINARY_ID, 
    uploadPreset: props.preset
  }, function (error: any, result: any){
    if(result && result.event === 'success'){
      props.onEventFromChild(result.info);
    }
  });

  return (
    <div className={props.className}>
      <button onClick={upload}> {props.placeholder}</button>
    </div>
  )
};
