import { Firestore, addDoc, collection, deleteDoc, doc, getDocs, query, where} from "firebase/firestore";
import { Match, Team } from "../../shared/interfaces/match.interface";
import { FirebaseError } from "firebase/app";
import { TrainingCategories } from "../../shared/enums/trainings.enum";
import { DBCollections } from "../../shared/enums/db.enum";

export class MatchService {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  public async getTeams(){
    try{
      const teamCollection = await getDocs(collection(this.db, DBCollections.Teams));
      return teamCollection;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async addTeam(team: Team){
    try{
      const docRef = await addDoc(collection(this.db, DBCollections.Teams), team);
      return docRef;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async removeTeam(teamId: string) {
    const teamDocRef = doc(this.db, DBCollections.Teams, teamId); 
    try {
      await deleteDoc(teamDocRef);
      return true;
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }


  public async getIncommingMatchs(){
    try{
      const matchesRef = collection(this.db, DBCollections.Matches);
      const matchCollection = await getDocs(query(matchesRef, where('date', '>=', new Date(Date.now())), where('date', '<', new Date('2099-01-01'))));
      return matchCollection;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }  
  }

  public filterByCategory(matchs: any,category: TrainingCategories){
    const formattedMatchs: Match[] = [];
    matchs.filter((match:any) => match.data().category === category).forEach((match:any) =>{
      const matchTmp = {
        team1: match.data().team1,
        team2: match.data().team2, 
        category: match.data().category,
        date: new Date(match.data().date.seconds * 1000).toString()
      }
      formattedMatchs.push(matchTmp);
    });
    return formattedMatchs
  }

  public getSoonestMatch(matchs: any){
    let soonestMatch: Match | null = null;
    if(matchs.length){
      const matchList: Match[] = [];
      matchs.forEach((match:any) => {
        matchList.push(
          {
            team1: match.data().team1,
            team2: match.data().team2, 
            category: match.data().category,
            date: new Date(match.data().date.seconds * 1000).toString()
          }
        );
      });
      const sortedMatches: Match[] = matchList.sort((a:Match, b:Match) => new Date(a.date).getTime() - new Date(b.date).getTime());
      const soonestDay = new Date(sortedMatches[0].date);
      let index = 0;
      for (const match of sortedMatches){
        const matchDateTmp = new Date(match.date);
        const areMatchesOnSameDay = (matchDateTmp.getFullYear() === soonestDay.getFullYear() &&
        matchDateTmp.getMonth() === soonestDay.getMonth() &&
        matchDateTmp.getDate() === soonestDay.getDate());
        if(!areMatchesOnSameDay) break;
        index +=1;
      }
      const lastDayMatches = sortedMatches.slice(0, index);
      soonestMatch = lastDayMatches.reduce((soonest: Match, match: Match,index: number) =>{
        if(index === 0){
          soonest = match;
        }else{
          if(+match.category.slice(1,match.category.length) < +soonest.category.slice(1,soonest.category.length)){
            soonest = match;
          }
        }
        return soonest;
      });
    }
    return soonestMatch;
  }

  public async addMatch(match: Match){
    try{
      const docRef = await addDoc(collection(this.db,DBCollections.Matches), match);
      return docRef;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async removeMatch(matchId: string){
    const teamDocRef = doc(this.db, DBCollections.Matches, matchId); 
    try {
      await deleteDoc(teamDocRef);
      return true;
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }  
  }
}