import React, { useEffect, useState } from 'react';
import styles from './lastArrivals.module.scss';
import Footer from '../../shared/footer/footer';
import Header from '../../shared/header/header';
import Mosaic from '../../shared/mosaic/mosaic';
import { MosaicProps } from '../../shared/interfaces/mosaic.interface';
import { LastArrival } from '../../shared/interfaces/lastArrival.interface';
import { auth, db } from '../../../App';
import { LastArrivalsService } from '../../core/service/lastArrivals.service';
import LastArrivalsAdminPanel from './last-arrivals-admin-panel';
import SmallRoundedButton from '../../shared/buttons/small-round-button/SmallRoundedButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useMediaQuery } from 'react-responsive';

const LastArrivals: React.FC = () => {
  const lastArrivalsService = new LastArrivalsService(db);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [lastArrivals, setLastArrivals] = useState<LastArrival[]>([]);
  const [showAdminPanel, setShowAdminPanel] = useState<boolean>(false);

  const getLastArrivals = async () => {
    const allArrivals = await lastArrivalsService.getArrivals();
    if(allArrivals){
      setLastArrivals(allArrivals);
    }else{
      console.error('No data');
    }
  }

  useEffect(() => {
    getLastArrivals();
  },[]);

  /**
   * UI functions
   */

  const lastArrivalsButtonProps = {
    onclickFct: () => setShowAdminPanel(!showAdminPanel),
    cssClass: styles.lastarrivalsadminbutton,
    text: 'Administrer les dernières arrivées'
  }

  const createMosaic = () => {
    const mosaicTemplate:MosaicProps = {
      images: [],
      imageHeight: 300,
      imagePerRow: isMobile ? 1 : 4,
    }
    lastArrivals.forEach(p => {
      mosaicTemplate.images.push({image: p.image, description: p.name});
    })
    return <Mosaic {...mosaicTemplate}></Mosaic>
  }
  
  return (
    <div className={styles.lastarrivals}>
      <Header></Header>
      <div className={styles.titlecontainer}>
        <h1 className={styles.agetitle}>Dernières arrivées</h1>
      </div>
      <div className={styles.main}>
        {
          useAuthState(auth)[0] !== null && 
            <div className={styles.buttoncontainer}>
              <SmallRoundedButton {...lastArrivalsButtonProps} ></SmallRoundedButton>
            </div>
        }
        {
          showAdminPanel && 
            <LastArrivalsAdminPanel></LastArrivalsAdminPanel>
        }
        <div className={styles.mosaiccontainer}>
          {
            createMosaic()
          }
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default LastArrivals;