import React from 'react';
import styles from './page-not-found.module.scss';
import Header from '../../shared/header/header';
import Footer from '../../shared/footer/footer';

const PageNotFound: React.FC = () => {
  return (
    <div className={styles.pagenotfound}>
      <Header></Header>
      <div className={styles.titlecontainer}>
        <h1 className={styles.pagetitle}>Error 404 : page non trouvée</h1>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PageNotFound;