import React, { useEffect, useState } from 'react';
import styles from './lastArrivals.module.scss';
import { db } from '../../../App';
import { CloudinaryUpload } from '../../shared/cloudinary/Cloudinary-upload';
import { CloudinaryPresets } from '../../shared/enums/cloudinary-presets.enum';
import { MosaicProps } from '../../shared/interfaces/mosaic.interface';
import Mosaic from '../../shared/mosaic/mosaic';
import 'react-datepicker/dist/react-datepicker.css'
import { useMediaQuery } from 'react-responsive';
import { LastArrival } from '../../shared/interfaces/lastArrival.interface';
import { LastArrivalsService } from '../../core/service/lastArrivals.service';

const LastArrivalsAdminPanel: React.FC = () => {

  const lastArrivalsService = new LastArrivalsService(db);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [lastArrivals, setLastArrivals] = useState<LastArrival[]>([]);
  const [name, setName] = useState<string>('');

  const getArrivals = async () => {
    const allArrivals = await lastArrivalsService.getArrivals(true);
    if(allArrivals && allArrivals.length > 0){
      setLastArrivals(allArrivals);
    }else{
      setLastArrivals([]);
      console.error('No data');
    }
  }

  const removeArrival = async (id: string) => {
    const arrivalRemoved = await lastArrivalsService.removeArrival(id); 
    if(arrivalRemoved){
      getArrivals();
    }
  }

  const addArrival = async (data: string) => {
    if(data && name !== ''){
      const lastArrival: LastArrival = { date: new Date(Date.now()), image: data, name };
      const addedArrival = await lastArrivalsService.addArrival(lastArrival);
      if(addedArrival){
        getArrivals();
      }
    }else{
      alert('Erreur dans la date ou lors de la sauvegarde de l\'image');
    }
  }

  const handleChildEvent = (data: any) => {
    addArrival(data.public_id);
  };

  useEffect(() => {
    getArrivals();
  },[]);


  /**
 * UI functions
 */

  const createMosaic = () => {
    const mosaicTemplate:MosaicProps = {
      images: [],
      imageHeight: 300,
      imagePerRow: isMobile ? 1 : 4,
      callback:removeArrival
    }
    lastArrivals.forEach(p => {
      mosaicTemplate.images.push({image: p.image, description: p.name, id: p.id});
    })
    return <Mosaic {...mosaicTemplate}></Mosaic>
  }

  
  return (
    <div className={styles.lastarrivalspanel}>
      <p>Ajouter de nouveaux arrivants :</p>
      <p>Nom</p>
      <input className={styles.playername} type="text" placeholder='Nom' value={name} onChange={ (e) => setName(e.target.value) }></input>
      <p>Photo</p>
      <div className={styles.cloudinaryuploadcontainer}>
        <CloudinaryUpload placeholder="Ajouter de nouveaux arrivants" preset={CloudinaryPresets.arrivals} className={styles.imginput} onEventFromChild={handleChildEvent}></CloudinaryUpload>
      </div>
      <div className={styles.mosaiccontainer}>
        {
          createMosaic()
        }
      </div>
    </div>
  );
}

export default LastArrivalsAdminPanel;