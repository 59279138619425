import { Firestore, addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query} from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { LastArrival } from "../../shared/interfaces/lastArrival.interface";
import { DBCollections } from "../../shared/enums/db.enum";


export class LastArrivalsService{
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  public async getArrivals(withId = false,nbDoc = 100){
    try{
      const arrivalsCollection = await getDocs(query(collection(this.db, DBCollections.LastArrivals), orderBy("date","desc"), limit(nbDoc)));
      const data: LastArrival[] = arrivalsCollection.docs.map(a => {
        if(withId){
          return {id: a.id, name: a.data().name, image: a.data().image,date: new Date(a.data().date.seconds * 1000)  }
        }else{
          return {name: a.data().name, image: a.data().image,date: new Date(a.data().date.seconds * 1000)  }
        }
      });
      return data;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async addArrival(arrivalData: LastArrival){
    try{
      const docRef = await addDoc(collection(this.db, DBCollections.LastArrivals), arrivalData);
      return docRef;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async removeArrival(arrivalId: string) {
    const docRef = doc(this.db, DBCollections.LastArrivals, arrivalId); 
    try {
      await deleteDoc(docRef);
      return true;
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }
}