import React, { useEffect, useState } from 'react';
import styles from './gallery.module.scss';
import Footer from '../../shared/footer/footer';
import Header from '../../shared/header/header';
import Mosaic from '../../shared/mosaic/mosaic';
import { GalleryPicture, GalleryMonth } from '../../shared/interfaces/gallery.interface';
import { MosaicProps } from '../../shared/interfaces/mosaic.interface';
import { auth, db } from '../../../App';
import { GalleryService } from '../../core/service/gallery.service';
import GalleryAdminPanel from './gallery-admin-panel';
import SmallRoundedButton from '../../shared/buttons/small-round-button/SmallRoundedButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useMediaQuery } from 'react-responsive';

const Gallery: React.FC = () => {
  const galleryService = new GalleryService(db);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [galleryMonths, setGalleryMonths] = useState<GalleryMonth[]>([]);

  const [showAdminPanel, setShowAdminPanel] = useState<boolean>(false);

  const getPictures = async () => {
    const allPictures = await galleryService.getPictures();
    if(allPictures && allPictures.length > 0){
      const picturesMonthlyOrdered:GalleryMonth[] = galleryService.groupByMonth(allPictures);
      setGalleryMonths(picturesMonthlyOrdered);
    }else{
      setGalleryMonths([]);
      console.error('No data');
    }
  }

  useEffect(() => {
    getPictures();
  },[]);

  /**
   * UI functions
   */

  const galleryButtonProps = {
    onclickFct: () => setShowAdminPanel(!showAdminPanel),
    cssClass: styles.galleryadminbutton,
    text: 'Administrer la galerie'
  }

  const createMosaic = (pics : GalleryPicture[]) => {
    const mosaicTemplate:MosaicProps = {
      images: [],
      imageHeight: 300,
      imagePerRow: isMobile ? 1 : 3,
    }
    pics.forEach(p => {
      mosaicTemplate.images.push({image: p.picture});
    })
    return <Mosaic {...mosaicTemplate}></Mosaic>
  }
  
  return (
    <div className={styles.gallery}>
      <Header></Header>
      <div className={styles.titlecontainer}>
        <h1 className={styles.pagetitle}>Galerie photo</h1>
      </div>
      <div className={styles.main}>
        {
          useAuthState(auth)[0] !== null && 
            <div className={styles.buttoncontainer}>
              <SmallRoundedButton {...galleryButtonProps} ></SmallRoundedButton>
            </div>
        }
        {
          showAdminPanel && 
            <GalleryAdminPanel></GalleryAdminPanel>
        }
        {
          galleryMonths.map((month:GalleryMonth) => {
            const dateTmp = month.month + " - " + month.year;
            return (
              <div key={dateTmp} className={styles.monthcontainer}>
                <h2 className={styles.monthdate}>{dateTmp}</h2>
                <div className={styles.mosaiccontainer}>
                  { createMosaic(month.pictures) }
                </div>
              </div>
            )
          })
        }
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Gallery;