import React, { useEffect, useState } from 'react';
import styles from './home.module.scss';
import mainImage from '../../../assets/group-coaching.jpg';
import gymnase1 from '../../../assets/gymnase1.png';
import gymnase2 from '../../../assets/gymnase2.png';
import SmallRoundedButton from '../../shared/buttons/small-round-button/SmallRoundedButton';
import { MosaicProps } from '../../shared/interfaces/mosaic.interface';
import Mosaic from '../../shared/mosaic/mosaic';
import { useNavigate } from "react-router-dom";
import Footer from '../../shared/footer/footer';
import Header from '../../shared/header/header';
import { RouterPaths } from '../../shared/enums/router-paths.enum';
import { GalleryService } from '../../core/service/gallery.service';
import { db } from '../../../App';
import { LastArrivalsService } from '../../core/service/lastArrivals.service';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const licenseText = 'Prendre sa licence';
  const galleryService = new GalleryService(db);
  const lastArrivalsService = new LastArrivalsService(db);

  const [lastArrivalMosaic, setLastArrivalMosaic] = useState<MosaicProps>({
    images: [],
    imageHeight: 400,
    imagePerRow: 4,
  });

  const [lastPicsMosaic, setLastPicsMosaic] = useState<MosaicProps>({
    images: [],
    imageHeight: 400,
    imagePerRow: 3,
  });  

  function navigateToGalery() {
    navigate(RouterPaths.Gallery);
  }

  const navigateToLicense = () => {
    navigate(RouterPaths.Licenses);
  }

  const navigateToLastArrivals = () => {
    navigate(RouterPaths.LastArrivals);
  }

  const downloadButtonProps = {
    onclickFct: navigateToLicense,
    cssClass: styles.licenseButton,
    text: licenseText
  }

  const getPictures = async () => {
    const lastPictures = await galleryService.getLastPictures(3);
    if(lastPictures && lastPictures.length > 0){
      const newLastPics = {...lastPicsMosaic};
      newLastPics.images = lastPictures;
      setLastPicsMosaic(newLastPics);
    }else{
      console.error('No data');
    }
  }

  const getArrivals = async () => {
    const allArrivals = await lastArrivalsService.getArrivals(false,8);
    if(allArrivals && allArrivals.length > 0){
      const newLastArrivals = {...lastArrivalMosaic};
      const mappedLastContent = allArrivals.map(a => {
        return { image: a.image, description: a.name}
      })
      newLastArrivals.images = mappedLastContent;
      setLastArrivalMosaic(newLastArrivals);
    }else{
      console.error('No data');
    }
  }

  useEffect(() => {
    getPictures();
    getArrivals();
  },[]);

  return (
    <div className={styles.homepage}>
      <Header></Header>
      <div className={styles.imagecontainer}>
        <img src={mainImage} className={styles.mainimage} alt='Accueil grande image'></img>
      </div>
      <div className={styles.main}>
        <div className={styles.contentseparator}></div>
        <div className={styles.buttoncontainer}>
          <SmallRoundedButton {...downloadButtonProps} ></SmallRoundedButton>
        </div>
        <div className={styles.contentseparator}></div>
        <h1>Notre club</h1>
        <div>
          <p>Notre club de Basket-ball a été créé en 2019 par Jacques Bodennec et Juan Jover.</p>
          <p>Il a pour vocation d'enseigner le Basket-ball avec nos valeurs :</p>
          <ul>
            <li>Respect des règles et de tous les acteurs de ce sport (adversaires, public, arbitres, encadrement)</li>
            <li>Convialité ...</li>
            <li> Le plaisir de tous se retrouver ensemble </li> 
          </ul>
          <p> ALORS N'HESITEZ PLUS, REJOIGNEZ-NOUS !!!</p>
        </div>
        <div className={styles.contentseparator}></div>
        <div className={styles.leftimagecontainer}>
          <div className={styles.subcontainerleft}>
            <img src={gymnase1}></img>
          </div>
          <div className={styles.subcontainerright}>
            <h2 className={styles.title}>SALLE POLYVALENTE ROGER DURAND</h2>
            <p>Rue Jean Vittoz, 38630</p>
            <p>Les Avenières Veyrins-Thuellin</p>
          </div>
        </div>
        <div className={styles.rightimagecontainer}>
          <div className={styles.subcontainerleft}>
            <h2 className={styles.title}>SALLE POLYVALENTE ROGER DURAND</h2>
            <p>Rue Jean Vittoz, 38630</p>
            <p>Les Avenières Veyrins-Thuellin</p>
          </div>
          <div className={styles.subcontainerright}>
            <img src={gymnase2}></img>
          </div>
        </div>
        <div className={styles.contentseparator}></div>
        <p>Dernières arrivées</p>
        <div>
          <Mosaic {...lastArrivalMosaic}></Mosaic>
          <p className={styles.lastpicsnavigate} onClick={navigateToLastArrivals}>
            VOIR LES DERNIERES ARRIVEES
          </p>
        </div>
        {
          lastPicsMosaic && 
          <div>
            <p>Dernières photos</p>
            <div>
              <Mosaic {...lastPicsMosaic}></Mosaic>
            </div>
            <p className={styles.lastpicsnavigate} onClick={navigateToGalery}>
            VOIR LA GALERIE
            </p>
          </div>
        }
        <div className={styles.contentseparator}></div>
        <div className={styles.buttoncontainer}>
          <SmallRoundedButton {...downloadButtonProps} ></SmallRoundedButton>
        </div>
        <div className={styles.contentseparator}></div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
