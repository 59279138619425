import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './app/pages/home/Home';
import Licenses from './app/pages/licenses/Licenses';
import Trainings from './app/pages/trainings/trainings';
import Matchs from './app/pages/matchs/matchs';
import Gallery from './app/pages/gallery/Gallery';
import { initializeApp } from "firebase/app";
import { getFirestore} from 'firebase/firestore';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { RouterPaths } from './app/shared/enums/router-paths.enum';
import Authentication from './app/pages/authentication/Authentication';
import LegalMentions from './app/pages/legal-mentions/LegalMention';
import CookieAcceptation from './app/shared/cookieAcceptation/CookieAcceptation';
import PageNotFound from './app/pages/page-not-found/PageNotFound';
import LastArrivals from './app/pages/last-arrivals/LastArrivals';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
(async () => {
  await setPersistence(auth, browserLocalPersistence);
})();

const router = createBrowserRouter([
  {
    path: RouterPaths.Home,
    element: <Home />,
  },
  {
    path: RouterPaths.Homelong,
    element: <Home />,
  },
  {
    path: RouterPaths.Licenses,
    element: <Licenses />
  },
  {
    path: RouterPaths.Trainings,
    element: <Trainings />
  },
  {
    path: RouterPaths.Matchs,
    element: <Matchs/>
  },
  {
    path: RouterPaths.Gallery,
    element: <Gallery/>
  },
  {
    path: RouterPaths.LastArrivals,
    element: <LastArrivals/>
  },
  {
    path: RouterPaths.LegalMentions,
    element: <LegalMentions/>
  },
  {
    path: RouterPaths.Authentication,
    element: <Authentication/>
  },
  {
    path: "*",
    element: <PageNotFound/>
  }
]);
router.subscribe(() => window.scrollTo(0, 0));

function App() {
  document.title = process.env.REACT_APP_PAGE_TITLE ? process.env.REACT_APP_PAGE_TITLE : 'AVTB';
  return (
    <div className="App">
      <RouterProvider router={router} />
      <CookieAcceptation></CookieAcceptation>
    </div>
  );
}

export default App;
