import { auth } from '../../../App';
import { signInWithEmailAndPassword  } from 'firebase/auth';



export class AuthUtils {

  public AuthenticateUser = async (username:string,password:string): Promise<boolean> => {
    const isAuthenticated = await signInWithEmailAndPassword(auth, username, password)
      .then( () => true)
      .catch(error => {
        console.error('Authentication error:', error);
        return false
      });
    return isAuthenticated;
  }
}

