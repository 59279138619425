import React from 'react';
import styles from './licenses.module.scss';
import { LicenseData } from '../../shared/interfaces/licenseData.interface';
import { LICENCES_DATA,NEW_LICENCE,RENEWALL, MUTATION, LICENCE_MAIL} from './licences-static-data';
import SmallRoundedButton from '../../shared/buttons/small-round-button/SmallRoundedButton';
import Header from '../../shared/header/header';
import Footer from '../../shared/footer/footer';

const Licenses: React.FC = () => {
  const data : LicenseData[] = LICENCES_DATA;

  return (
    <div className={styles.licenses}>
      <Header></Header>
      <div className={styles.titlecontainer}>
        <h1 className={styles.pagetitle}>Licences</h1>
      </div>
      <div className={styles.main}>
        <h2>Prix de nos licences :</h2>
        {
          data.map((d: LicenseData) => {
            return (
              <div key={d.category}>
                <div className={styles.licensedescriptioncontainer}>
                  <div className={styles.licensedescription}>
                    <div className={styles.textcontainer}>
                      <span>{d.category}</span>
                      <span>{d.age}</span>
                      <span>{d.price}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.inline3buttoncontainer}>
                  <a href={`mailto:${LICENCE_MAIL}?subject=${NEW_LICENCE} - ${d.category}`} className={styles.mailtolink}><SmallRoundedButton cssClass={styles.licenseButton} text={NEW_LICENCE}></SmallRoundedButton></a>
                  <a href={`mailto:${LICENCE_MAIL}?subject=${RENEWALL} - ${d.category}`} className={styles.mailtolink}><SmallRoundedButton cssClass={styles.licenseButton} text={RENEWALL}></SmallRoundedButton></a>
                  <a href={`mailto:${LICENCE_MAIL}?subject=${MUTATION} - ${d.category}`}  className={styles.mailtolink}><SmallRoundedButton cssClass={styles.licenseButton} text={MUTATION}></SmallRoundedButton></a>
                </div>                
              </div>
            )
          })
        }
      </div>
      <Footer></Footer>
    </div> 
  )
}

export default Licenses;
