import React, { useEffect, useState } from 'react';
import styles from './gallery.module.scss';
import { GalleryService } from '../../core/service/gallery.service';
import { db } from '../../../App';
import { CloudinaryUpload } from '../../shared/cloudinary/Cloudinary-upload';
import { CloudinaryPresets } from '../../shared/enums/cloudinary-presets.enum';
import { GalleryMonth, GalleryPicture } from '../../shared/interfaces/gallery.interface';
import { MosaicProps } from '../../shared/interfaces/mosaic.interface';
import Mosaic from '../../shared/mosaic/mosaic';
import { Utils } from '../../shared/utils/utils';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { useMediaQuery } from 'react-responsive';

const GalleryAdminPanel: React.FC = () => {

  const utils = new Utils();
  const galleryService = new GalleryService(db);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [galleryMonths,setGalleryMonths] = useState<GalleryMonth[]>([]);
  const [pictureDate, setPictureDate] = useState<Date | null>(new Date(Date.now()));

  const getPictures = async () => {
    const allPictures = await galleryService.getPictures();
    if(allPictures && allPictures.length > 0){
      const picturesMonthlyOrdered:GalleryMonth[] = galleryService.groupByMonth(allPictures);
      setGalleryMonths(picturesMonthlyOrdered);
    }else{
      setGalleryMonths([]);
      console.error('No data');
    }
  }

  const removePicture = async (id: string) => {
    const pictureRemoved = await galleryService.removePicture(id); 
    if(pictureRemoved){
      getPictures();
    }
  }

  const addPicture = async (data: string) => {
    if(data && pictureDate){
      const picture: GalleryPicture = { date: pictureDate, picture: data };
      const addedPicture = await galleryService.addPicture(picture);
      if(addedPicture){
        getPictures();
      }
    }else{
      alert('Erreur dans la date ou lors de la sauvegarde de l\'image');
    }
  }

  const handleChildEvent = (data: any) => {
    addPicture(data.public_id);
  };

  useEffect(() => {
    getPictures();
  },[]);


  /**
 * UI functions
 */

  const createMosaic = (pics : GalleryPicture[]) => {
    const mosaicTemplate:MosaicProps = {
      images: [],
      imageHeight: 200,
      imagePerRow: isMobile ? 2 : 8,
      callback:removePicture
    }
    pics.forEach(p => {
      mosaicTemplate.images.push({image: p.picture, description: utils.FormatDateToDisplay(p.date), id: p.id});
    })
    return <Mosaic {...mosaicTemplate}></Mosaic>
  }

  
  return (
    <div className={styles.gallerypanel}>
      <p>Ajouter des photos à la galerie :</p>
      <p>Date</p>
      <div className={styles.datepickercontainer}>
        <DatePicker dateFormat="dd/MM/yyyy" selected={pictureDate} onChange={(date) => setPictureDate(date)} />
      </div>
      <p>Photos</p>
      <div className={styles.cloudinaryuploadcontainer}>
        <CloudinaryUpload placeholder="Ajouter des images à la galerie" preset={CloudinaryPresets.gallery} className={styles.imginput} onEventFromChild={handleChildEvent}></CloudinaryUpload>
      </div>
      {
        galleryMonths.map((month:GalleryMonth) => {
          const dateTmp = month.month + " - " + month.year;
          return (
            <div key={dateTmp} className={styles.monthcontainer}>
              <h2 className={styles.monthdate}>{dateTmp}</h2>
              <div className={styles.mosaiccontainer}>
                { createMosaic(month.pictures) }
              </div>
            </div>
          )
        })
      }
    </div>
  );
}

export default GalleryAdminPanel;