import React, { useState } from 'react';
import styles from './authentication.module.scss';
import Footer from '../../shared/footer/footer';
import Header from '../../shared/header/header';
import { AuthUtils } from '../../shared/utils/auth-utils';
import { useNavigate } from "react-router-dom";
import { RouterPaths } from '../../shared/enums/router-paths.enum';
const Authentication: React.FC = () => {

  const authFct = new AuthUtils();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const tryAuth = async () => {
    const isAuthenticated = await authFct.AuthenticateUser(username,password);
    if(isAuthenticated) navigate(RouterPaths.Home);
  }

  return (
    <div className={styles.authentication}>
      <Header></Header>
      <div className={styles.formcontainer}>
        <div className={styles.fieldcontainer}>
          <div className={styles.formgroup}>
            <label htmlFor="username">Identifiant</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.formgroup}>
            <label htmlFor="password">Mot de passe</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className={styles.showpassword}
              onClick={toggleShowPassword}
            >
              {showPassword ? 'Cacher' : 'Montrer'}
            </button>
          </div>
          <button className={styles.submitauth} onClick={() => tryAuth()}>Se connecter</button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
};

export default Authentication;