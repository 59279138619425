import React, { useMemo,  useRef,  useState } from 'react';
import styles from './trainings.module.scss';
import Header from '../../shared/header/header';
import Footer from '../../shared/footer/footer';
import mainImage from '../../../assets/interieur-gymnase.jpg';
import { CloudinaryComponent } from '../../shared/cloudinary/Cloudinary';
import { trainingsStaticData } from './trainings-static-data';
import TrainingsModal from './trainings-modal';

const Trainings: React.FC = () => {

  const titleTemplate = 'Entrainement ';
  const descriptionTemplate = <div></div>;
  const modalRef = useRef<any>();

  const [title, setTitle] = useState(titleTemplate) ;
  const [content, setContent] = useState(descriptionTemplate);
  const [showModal,  setShowModal] = useState(false);

  const setModal = (newTitle: string,newContent : JSX.Element,e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    setTitle(titleTemplate + newTitle);
    setContent(newContent);
    setShowModal(true);
  }

  const handleOutsideClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if(!modalRef?.current?.contains(e.target)) {
      setShowModal(false);
    }
  };

  const staticPart = useMemo(() => {
    return (
      <div>
        <div className={styles.trainingsmosaic}>
          {
            trainingsStaticData.map(t => {
              return (
                <div className={styles.trainingbloc} key={t.title} onClick={(e:React.MouseEvent<HTMLInputElement>) => setModal(t.title,t.description,e)}>
                  <CloudinaryComponent {...t.imageRef}></CloudinaryComponent>
                  <div className={styles.textcontainer}>
                    <p className={styles.hiddentext}>{t.title}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }, [])
  
  return (
    <div className={styles.trainings} onClick={(e: React.MouseEvent<HTMLInputElement>) => handleOutsideClick(e)}>
      <Header></Header>
      <div className={styles.imagecontainer}>
        <img src={mainImage} className={styles.mainimage}></img>
      </div>
      <div className={styles.main}>
        <h1 className={styles.pagetitle}>Entrainements</h1>
        <div className={styles.contentcontainer}>
          {staticPart}
          <div ref={modalRef}>
            {showModal && 
            <TrainingsModal title={title} content={content} />
            }
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div> 
  )
}

export default Trainings;
