import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieAcceptation: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="J'accepte"
      cookieName="userAcceptedCookies"
      style={{background: '#0075f2'}}
      buttonStyle={{ color: "#ECE5F0", fontSize: "16px", backgroundColor: "#252422"}}
      expires={150}
    >
      Ce site utilises des cookies pour fournir une expérience de navigation plus sûre.
      Si vous ne voulez pas vous en servir, vous pouvez désactiver les cookies dans votre navigateur.
    </CookieConsent>
  )
}

export default CookieAcceptation;