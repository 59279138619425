import React, { useEffect, useState } from 'react';
import styles from './matchs.module.scss';
import Header from '../../shared/header/header';
import Footer from '../../shared/footer/footer';
import { TrainingCategories } from '../../shared/enums/trainings.enum';
import { Match } from '../../shared/interfaces/match.interface';
import { CloudinaryComponent } from '../../shared/cloudinary/Cloudinary';
import { MatchService } from '../../core/service/matchs.service';
import { Utils } from "../../shared/utils/utils";
import { auth, db } from '../../../App';
import MatchAdminPanel from './match-admin-panel';
import { useAuthState } from 'react-firebase-hooks/auth';

const Matchs: React.FC = () => {

  const utils = new Utils();

  const matchService = new MatchService(db);
  const [matchs,setMatchs] = useState<any[]>([])
  const [soonestMatch,setSoonestMatch] = useState<Match | null>(null)

  const getMatches = async () => {
    try{
      const matchCollection = await matchService.getIncommingMatchs();
      if(matchCollection){
        setMatchs(matchCollection.docs);
        setSoonestMatch(matchService.getSoonestMatch(matchCollection.docs));
      }else{
        console.error('no data');
      }
    }catch(err){
      console.error('error fetching matches', err);
    }
  }  

  useEffect(() => {
    getMatches();
  },[]);

  /**
   * UI functions
   */

  const categoriesToBeDisplayed = Object.keys(TrainingCategories).filter( k => k !== TrainingCategories.U7 && k !== TrainingCategories.U9 && k !== TrainingCategories.U11); 

  return (
    <div className={styles.matchs}>
      <Header></Header>
      <div className={styles.titlecontainer}>
        <div className={styles.titlecontainerdivision}>
          <h1 className={styles.pagetitle}>Matchs en approche</h1>
        </div>
        {
          soonestMatch && (
            <div className={styles.titlecontainerdivision}> 
              <div className={styles.titlematchcontainer}>
                <p className={styles.matchcategory}>{soonestMatch.category}</p>
                <div className={styles.matchcontainer}>
                  <div className={styles.firstclub}>
                    <CloudinaryComponent {...{imgName:soonestMatch.team1.logo}}></CloudinaryComponent>
                    <p>{soonestMatch.team1.teamName}</p>
                  </div>
                  <div className={styles.separator}>/</div>
                  <div className={styles.secondclub}>
                    <CloudinaryComponent {...{imgName:soonestMatch.team2.logo}}></CloudinaryComponent>
                    <p>{soonestMatch.team2.teamName}</p>
                  </div>
                </div>
                <h2 className={styles.matchdate}>{utils.FormatDateToDisplay(new Date(soonestMatch.date))}</h2>
              </div>
            </div>
          )
        }
      </div>
      <div className={styles.main}>
        { 
          categoriesToBeDisplayed.map((k) => {
            return (
              <div key={k} className={styles.categorycontainer}>
                <h2 className={styles.categorytitle}>{k}</h2>
                <div className={styles.matchlinecontainer}>
                  {
                    matchService.filterByCategory(matchs,k as TrainingCategories).slice(0, 3).map(match => {
                      return (
                        <div key={match.team1.teamName + match.team2.teamName + match.date} className={styles.matchkey}>
                          <div className={styles.matchcontainer}>
                            <div className={styles.firstclub}>
                              <CloudinaryComponent {...{imgName:match.team1.logo}}></CloudinaryComponent>
                              <p>{match.team1.teamName}</p>
                            </div>
                            <div className={styles.separator}>/</div>
                            <div className={styles.secondclub}>
                              <CloudinaryComponent {...{imgName:match.team2.logo}}></CloudinaryComponent>
                              <p>{match.team2.teamName}</p>
                            </div>
                          </div>
                          <div className={styles.matchdate}>{utils.FormatDateToDisplay(new Date(match.date))}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
        {
          useAuthState(auth)[0] !== null &&
            <>
              <h2>Configuration des matchs</h2>
              <MatchAdminPanel></MatchAdminPanel>
            </>
        }
      </div>
      <Footer></Footer>
    </div> 
  )
}

export default Matchs;
