import React, { useEffect, useState } from 'react';
import styles from './matchs.module.scss';
import { MatchService } from '../../core/service/matchs.service';
import { db } from '../../../App';
import { Match, Team } from '../../shared/interfaces/match.interface';
import { CloudinaryComponent } from '../../shared/cloudinary/Cloudinary';
import { CloudinaryUpload } from '../../shared/cloudinary/Cloudinary-upload';
import { CloudinaryPresets } from '../../shared/enums/cloudinary-presets.enum';
import { TrainingCategories } from '../../shared/enums/trainings.enum';
import { Utils } from "../../shared/utils/utils";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

const MatchAdminPanel: React.FC = () => {

  const utils = new Utils();

  const matchService = new MatchService(db);
  const [matchs,setMatchs] = useState<any[]>([])
  const [teams,setTeams] = useState<any[]>([])

  const [showTeamForm, setShowTeamForm] = useState(false);
  const [teamName,setTeamName] = useState<string>('');
  const [teamLogo,setTeamLogo] = useState<string>('');
  const [computedTeamClass, setComputedTeamClass] = useState<string>(styles.teamcell)

  const [showMatchForm, setShowMatchForm] = useState(false);
  const [matchDate,setMatchDate] = useState<any>(Date.now());
  const [matchCategory,setMatchCategory] = useState<TrainingCategories | string>("none");
  const [matchTeam1,setMatchTeam1] = useState<any>(null);
  const [matchTeam2,setMatchTeam2] = useState<any>(null);

  const addTeam = async () => {
    if(teamName !== '' && teamLogo !== ''){
      const team: Team = {
        teamName: teamName,
        logo: teamLogo,
      }
      const addedTeam: any = await matchService.addTeam(team);
      if(addedTeam.id){
        getTeams();
        setTeamName('');
        setTeamLogo('');
      }
    }else{
      alert("Erreur : pas de nom d'équipe ou de logo selectionné");
    }
  }

  const getTeams = async () => {
    try{
      const teamCollection = await matchService.getTeams();
      if(teamCollection){
        setTeams(teamCollection.docs);
      }else{
        console.error('no data');
      }
    }catch(err){
      console.error('error fetching teams', err);
    }
  }  

  const removeTeam = async (teamId:string) => {
    if(!showMatchForm){
      try{
        const deletedTeam = await matchService.removeTeam(teamId);
        if(deletedTeam){
          getTeams();
        }
      }catch(err){
        console.error('error deleting team', err);
      }
    }
  }

  const addMatch = async () => {
    if(matchCategory !== "none" && matchDate >= Date.now()) {
      if(matchTeam1 && matchTeam2){
        const team1Data = {teamName: matchTeam1.data().teamName, logo: matchTeam1.data().logo};
        const team2Data = {teamName: matchTeam2.data().teamName, logo: matchTeam2.data().logo};
        const newMatch: Match = {
          team1: team1Data,
          team2: team2Data,
          date: matchDate,
          category: matchCategory as TrainingCategories
        }
        const addedMatch = await matchService.addMatch(newMatch);
        if(addedMatch){
          getMatches();
          resetMatch();
        }
      }else{
        alert('Erreur : il faut au moins 2 équipes selectionnées');
      }
    }else{
      alert('Erreur : formulaire de création de match incomplet ou mal rempli');
    }
  }

  const getMatches = async () => {
    try{
      const matchCollection = await matchService.getIncommingMatchs();
      if(matchCollection){
        setMatchs(matchCollection.docs);
      }else{
        console.error('no data');
      }
    }catch(err){
      console.error('error fetching matches', err);
    }
  }  

  const removeMatch = async (matchId:string) => {
    try{
      const deletedMatch = await matchService.removeMatch(matchId);
      if(deletedMatch){
        getMatches();
      }
    }catch(err){
      console.error('error deleting team', err);
    }
  }

  const resetMatch = () => {
    setMatchDate(Date.now());
    setMatchCategory('');
    setMatchTeam1(null);
    setMatchTeam2(null);
  }

  const handleChildEvent = (data: any) => {
    setTeamLogo(data.public_id);
  };

  /**
   * 
   * @param teamClick 
   * @param index 
   * If user clicks the first team is selected
   * If user clicks on another second team is selected
   * However, if user clicks on the first team, the first team is unselected
   * If user has clicked 2 teams, the second team is selected
   * If user has 2 teams and clicks on the first one, nothing happens
   * If user has 2 teams and clicks on the second one, it is unselected
   */
  const handleTeamClick = (teamClick: any) => {
    if(showMatchForm){
      if(matchTeam1 && matchTeam2){
        if(teamClick.id === matchTeam2.id){
          setMatchTeam2(null);
        }else{
          setMatchTeam2(teamClick);
        }
      }else if (matchTeam1){
        if(teamClick.id === matchTeam1.id){
          setMatchTeam1(null);
        }else{
          setMatchTeam2(teamClick);
        }
      }else{
        setMatchTeam1(teamClick);
      }
    }    
  }

  useEffect(() => {
    getMatches();
    getTeams();
  },[]);

  /* UI functions */

  const launchMatchForm = (bool: boolean) => {
    bool ? setComputedTeamClass(styles.teamcell + ' ' + styles.canbeselected) : setComputedTeamClass(styles.teamcell);
    setShowMatchForm(bool);
    if(!bool){
      resetMatch();
    }
  }

  const recomputeTeamClass = (team: any) => {
    let recomputedTeamClass = computedTeamClass;
    if(matchTeam1 && team.id === matchTeam1.id){
      recomputedTeamClass = computedTeamClass + ' ' + styles.matchteam1;
    }else if(matchTeam2 && team.id === matchTeam2.id){
      recomputedTeamClass = computedTeamClass + ' ' + styles.matchteam2;
    }
    return recomputedTeamClass;
  }

  return (
    <div className={styles.matchpanel}>
      <h3 className={styles.paneltitle}>Liste des matchs à venir</h3>
      <div className={styles.panelbuttoncontainer}>
        {
          showMatchForm ?
            <button className={styles.panelbutton} onClick={() => launchMatchForm(false)}>Masquer l'ajout de match</button>
            :
            <button className={styles.panelbutton} onClick={() => launchMatchForm(true)}>Ajouter un match</button>
        }
      </div>
      {
        !showMatchForm && 
          <div className={styles.panelgridmatch}>
            {
              matchs.map((match:any) => {
                const matchId = match.id;
                match = match.data();
                return (
                  <div key={match.id} className={styles.matchcell}>
                    <div className={styles.matchcontainer}>
                      <div className={styles.firstclub}>
                        <p className={styles.adminclubname}>{match.team1.teamName}</p>
                        <CloudinaryComponent {...{imgName: match.team1.logo}}></CloudinaryComponent>
                      </div>
                      <div className={styles.separator}>/</div>
                      <div className={styles.secondclub}>
                        <p className={styles.adminclubname}>{match.team2.teamName}</p>
                        <CloudinaryComponent {...{imgName: match.team2.logo}}></CloudinaryComponent>
                      </div>
                    </div>
                    <div className={styles.matchdate}>{match.category + ' - ' + utils.FormatDateToDisplay(new Date(match.date.seconds*1000))}</div>
                    <div className={styles.removematch} onClick={() => removeMatch(matchId)}>X</div>
                  </div>
                )
              })
            }
          </div> 
      }
      {
        showMatchForm &&
        <div>
          <div className={styles.matchform}>
            <div className={styles.datecontainer}>
              <p>Date</p>
              <div className={styles.datepickercontainer}>
                <DatePicker dateFormat="dd/MM/yyyy" selected={matchDate} onChange={(date) => setMatchDate(date)} />
              </div>
            </div>
            <div className={styles.categorycontainer}>
              <p>Catégorie</p>
              <select className={styles.selectcategory} value={matchCategory}  onChange={ (e) => setMatchCategory(e.target.value) }>
                <option value="none">Selectionnez une option</option>
                {
                  Object.keys(TrainingCategories).map((category) => {
                    return <option key={category} value={category}>{category}</option>
                  })
                }
              </select>
            </div>        
            <div className={styles.validation}>
              <div className={styles.panelbuttoncontainer}>
                <button className={styles.panelbutton} onClick={() => addMatch()}>valider</button>
              </div>
            </div>
          </div>
          <p className={styles.explication}>Cliquez sur une des équipes ci-dessous pour la sélectionner</p>        
        </div>
      }
    
      <h3 className={styles.paneltitle}>Liste des équipes</h3>
      <div className={styles.panelbuttoncontainer}>
        {
          showTeamForm ? 
            <button className={styles.panelbutton} onClick={() => setShowTeamForm(false)}>Masquer la création d'équipe</button>
            :
            <button className={styles.panelbutton} onClick={() => setShowTeamForm(true)}>Créer une nouvelle équipe</button>
        }
      </div>
      {
        showTeamForm && (
          <div className={styles.createteam}>
            <div className={styles.newname}>
              <p>Nom</p>
              <input type="text" placeholder="Nom de l'équipe" value={teamName} onChange={ (e) => setTeamName(e.target.value) }></input>
            </div>
            <div className={styles.newlogo}>
              <p>Logo</p>
              <CloudinaryUpload placeholder="Ajouter un logo" preset={CloudinaryPresets.logos} className='logoinput' onEventFromChild={handleChildEvent}></CloudinaryUpload>
            </div>
            <div className={styles.validation}>
              <div className={styles.panelbuttoncontainer}>
                <button className={styles.panelbutton} onClick={() => addTeam()}>valider</button>
              </div>
            </div>
          </div>
        )
      }
      <div className={styles.panelteamgrid}>
        {
          teams.map((team:any) => {
            return (
              <div key={team.id} className={recomputeTeamClass(team)} onClick={() => handleTeamClick(team)}>
                <p className={styles.teamtitle}>{team.data().teamName}</p>
                <CloudinaryComponent imgName={team.data().logo}></CloudinaryComponent>
                <div className={styles.removeteam} onClick={() => removeTeam(team.id)}>X</div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default MatchAdminPanel;