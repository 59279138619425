import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from 'react-router-dom';
import { RouterPaths } from '../enums/router-paths.enum';
import './mobile-sidebar.scss';

const MobileSidebar: React.FC = () => {
  return (
    <div >
      <Menu right>
        <div><Link to={RouterPaths.Homelong}>LE CLUB</Link></div>
        <div><Link to={RouterPaths.Matchs}>MATCHS</Link></div>
        <div><Link to={RouterPaths.Trainings}>ENTRAINEMENTS</Link></div>
      </Menu>
    </div>
  );
};

export default MobileSidebar;
