import { Firestore, addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query} from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { GalleryMonth, GalleryPicture } from "../../shared/interfaces/gallery.interface";
import { ImageAndDescription } from "../../shared/interfaces/mosaic.interface";
import { DBCollections } from "../../shared/enums/db.enum";


export class GalleryService {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  /**
   * 
   * @param pictures all the pictures ordered by last date
   * @returns 
   */
  public groupByMonth(pictures: GalleryPicture[]){
    const galleryMonths: GalleryMonth[] = [];
    let currentYear = pictures[0].date.getFullYear();
    let currentMonth = pictures[0].date.toLocaleString("fr-FR",{month: "long"});
    let gmIndex = -1;
    pictures.forEach((pic: GalleryPicture) => {
      const picYear = pic.date.getFullYear();
      const picMonth = pic.date.toLocaleString("fr-FR",{month: "long"})
      if(galleryMonths.length === 0 || currentMonth !== picMonth){
        currentYear = picYear; 
        currentMonth = picMonth;
        galleryMonths.push(
          {
            month: currentMonth,
            year: currentYear,
            pictures: []
          }
        );
        gmIndex = gmIndex+1;
      } 
      if(gmIndex >= 0) galleryMonths[gmIndex].pictures.push(pic);
    });
    return galleryMonths;
  }

  public async getPictures(){
    try{
      const pictureCollection = await getDocs(collection(this.db, DBCollections.Gallery));
      const data: GalleryPicture[] = pictureCollection.docs.map(p => {
        return {id: p.id, date: new Date(p.data().date.seconds * 1000), picture: p.data().picture }
      });
      return data.length > 0 ? data.sort((a,b) => {return b.date.getTime() - a.date.getTime()}) : [];
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async getLastPictures(nbPicture:number){
    try{
      const pictureCollection = await getDocs(query(collection(this.db, DBCollections.Gallery), orderBy("date","desc"), limit(nbPicture)));
      const data: ImageAndDescription[] = pictureCollection.docs.map(pic => {
        return {image: pic.data().picture}
      });
      return data;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async addPicture(picData: GalleryPicture){
    try{
      const docRef = await addDoc(collection(this.db, DBCollections.Gallery), picData);
      return docRef;
    }catch(e: unknown){
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }

  public async removePicture(picId: string) {
    const picDocRef = doc(this.db, DBCollections.Gallery, picId); 
    try {
      await deleteDoc(picDocRef);
      return true;
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        console.error(e.message);
      }
    }
  }
}