import React from 'react';
import style from './trainings.module.scss'

export const trainingsStaticData = [
  {
    title: 'U7/U9',
    imageRef: {imgName:'avtb_example'},
    description: 
    <div>
      <p>Mercredi 17h-18h</p>
      <div className={style.separator}></div>
      <p>Salle Polyvalente Roger Durand Rue Jean Vittoz</p>
    </div>
  },
  {
    title: 'U11',
    imageRef: {imgName:'avtb_example'},
    description: 
    <div>
      <p>Les entrainement U11 ne sont pas organisés cette année.</p>
    </div>
  },
  {
    title: 'U13',
    imageRef: {imgName:'avtb_example'},
    description: 
    <div>
      <p>Mardi 17h-18h</p>
      <p>Jeudi 18h30-20h</p>
      <div className={style.separator}></div>
      <p>Salle Polyvalente Roger Durand Rue Jean Vittoz</p>
    </div>
  },
  {
    title: 'U15',
    imageRef: {imgName:'avtb_example'},
    description:
    <div>
      <p>Mardi 17h-18h</p>
      <p>Jeudi 18h30-20h</p>
      <p>Vendredi 20h-22h détente</p>
      <div className={style.separator}></div>
      <p>Salle Polyvalente Roger Durand Rue Jean Vittoz</p>
    </div>
  },
  {
    title: 'U17',
    imageRef: {imgName:'avtb_example'},
    description:
     <div>
       <p>Lundi  19h-20h30</p>
       <p>Mercredi 19h-20h30 ou 20h-21h30</p>
       <p>Vendredi 20h-22h détente</p>
       <div className={style.separator}></div>
       <p>Salle Polyvalente Roger Durand Rue Jean Vittoz</p>
     </div>
  },
  {
    title: 'U20',
    imageRef: {imgName:'avtb_example'},
    description:
    <div>
      <p>Lundi  19h-20h30</p>
      <p>Mercredi 19h-20h30 ou 20h-21h30</p>
      <p>Vendredi 20h-22h détente</p>
      <div className={style.separator}></div>
      <p>Salle Polyvalente Roger Durand Rue Jean Vittoz</p>
    </div>
  }
]