import React from 'react';
import styles from './small-round-button.module.scss';

interface SmallRoundedButtonProps {
  onclickFct?: () => void;
  cssClass: string;
  text: string
}

const SmallRoundedButton: React.FC<SmallRoundedButtonProps> = (props:SmallRoundedButtonProps) => {
  return (
    <div>
      <div role="button" className={`${props.cssClass} ${styles.buttonbasics}`} onClick={props.onclickFct}> {props.text} </div>
    </div>
  )
}
  
export default SmallRoundedButton;