import { TrainingCategories } from "../../shared/enums/trainings.enum";

export const LICENCES_DATA  = [
  {
    category: TrainingCategories.U7 + '/' + TrainingCategories.U9,
    age: '5-8 ans',
    price: '45€',
  },
  {
    category: TrainingCategories.U11,
    age: '9-10 ans',
    price: '55€',
  },
  {
    category: TrainingCategories.U13,
    age: '11-12 ans',
    price: '65€',
  },
  {
    category: TrainingCategories.U15,
    age: '13-14 ans',
    price: '75€',
  },
  {
    category: TrainingCategories.U17,
    age: '15-16 ans',
    price: '85€',
  },
  {
    category: TrainingCategories.U20,
    age: '17-19 ans',
    price: '95€',
  }
]

export const NEW_LICENCE = 'Prendre sa licence';
export const RENEWALL = 'Renouvellement';
export const MUTATION = 'Mutation';

export const LICENCE_MAIL = 'matija.bodennec@gmail.com';