import React from 'react';
import styles from './footer.module.scss';
import logo from '../../../assets/logo.png';
import logoFb from '../../../assets/logo-facebook-noir.png';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../enums/router-paths.enum';


const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.firsthalf}>
        <div className={styles.left}>
          <ul className={styles.titlecontainer}>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Homelong}>LE CLUB</Link></li>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Matchs}>MATCHS</Link></li>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Trainings}>ENTRAINEMENTS</Link></li>
          </ul>
        </div>
        <div className={styles.logocontainer}>
          <img src={logo} alt='Logo AVTBasketball'></img>
        </div>
        <div className={styles.right}>
          <ul className={styles.titlecontainer}>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Licenses}>LICENCES</Link></li>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Gallery}>GALERIE PHOTOS</Link></li>
            <li className={styles.redirecttitle}><Link to={RouterPaths.Authentication}>ADMINISTRATION</Link></li>
          </ul>
        </div>
      </div>
      <div className={styles.secondhalf}>
        <div className={styles.fblogocontainer}>
          <a href="https://www.facebook.com/profile.php?id=100070217439443&locale=fr_FR" target='blank'><img className={styles.fblogo} src={logoFb} alt='Lien vers facebook'></img></a>
        </div>
        <div className={styles.line}></div>
        <div className={styles.end}>
          <div className={styles.blankspace}></div>
          <div className={styles.rights}>AVTB © 2022. TOUS DROITS RÉSERVÉS.</div>
          <div className={styles.legalmentions}><Link to={RouterPaths.LegalMentions}>MENTIONS LÉGALES</Link></div>
        </div>
      </div>
    </div> 
  )
}

export default Footer;
