import React from 'react';
import styles from './trainings.module.scss';

const TrainingsModal: React.FC<{ title: string; content: JSX.Element; }> = ({ title, content }) => {
  return (
    <div className={styles.trainingsmodal}>
      <h2 className={styles.modaltitle}>{title}</h2>
      <div className={styles.modalcontent}>{content}</div>
    </div>
  );
};

export default TrainingsModal;
